import React, { Fragment, useState } from "react";
import { CopyBlock, dracula } from "react-code-blocks";
import { useAccount } from 'wagmi';
import swal from "sweetalert";
import loaderImage from '../../../images/loader-1.gif';

export default function AuditBoard({ onAudit, result, loading, setResult, error, setError }) {
    const [formData, setFormData] = useState({
        script: ''
    });

    const { isConnected } = useAccount();

    const [validate, setValidate] = useState(false);

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };

    const onReset = () => {
        setValidate(false);
        setResult("");
        setError("");
        setFormData({
            script: ''
        });
    }

    return (
        <div className="row">
            <div className="col-xl-6">
                <div className="card">
                    <div className="card-body">
                        <h4 className="mb-0">Audit Your Smart Contract</h4>
                        <hr />
                        <section>
                            <div className="row">

                                <div className="col-lg-12 mb-2">
                                    <div className="form-group mb-3">
                                        <label className="text-label">Enter Code*</label>
                                        <textarea style={{ height: "300px" }} name="script" value={formData.script} onChange={handleChange} className={!formData.script && validate ? "form-control border-danger" : "form-control"}></textarea>
                                    </div>
                                </div>

                            </div>
                        </section>
                        <hr />
                        <div class="text-end p-2"><button onClick={onReset} className="btn btn-warning light">Clear</button> <button class="btn btn-primary" onClick={() => {
                            if (isConnected) {
                                if (formData.script) {
                                    onAudit(formData);
                                } else {
                                    setValidate(true);
                                }
                            } else {
                                swal("Please Connect Wallet");
                            }

                        }} disabled={loading}>Audit</button></div>
                    </div>
                </div>
            </div>
            <div className="col-xl-6">
                <div className="card">
                    <div className="card-body align-items-center justify-content-center d-flex min-h-300">
                        <section className="w-100">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div>
                                        {!loading && (result.length <= 0 && error.length <= 0) ? <h6 className="display-6 fs-22 text-muted text-center">Please Enter Your Smart Contract Code to Audit</h6> : ""}
                                        {loading ? <Fragment><div role="alert" className="fade alert alert-info alert-dismissible show fs-16 mb-3"><i className="fa fa-info-circle"></i><strong> Stay Tune! </strong> Our Ai will audit your contract for your need.</div><img className="img-fluid" src={loaderImage} alt="loading" /></Fragment> : (<div className="code-format">
                                            {result.length > 0 ? <CopyBlock
                                                text={result}
                                                language="typescript"
                                                showLineNumbers={true}
                                                theme={dracula}
                                                codeBlock
                                            /> : ""}
                                        </div>)}
                                        {error.length > 0 ? <div className="display-6 fs-18 text-danger p-5">{error}</div> : ""}
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div >
    );
}