import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Link } from 'react-router-dom';

//Import Components
import { ThemeContext } from "../../../context/ThemeContext";
import BalanceCardSlider from './Dashboard/BalanceCardSlider';

//images
import coin from './../../../images/coin.png';
import preSaleImage from './../../../images/presale.png';


const Home = () => {
	const { changeBackground } = useContext(ThemeContext);
	useEffect(() => {
		changeBackground({ value: "light", label: "Light" });
	}, []);

	return (
		<>
			<div className="row">
				<div className="col-xl-9">
					<div className="row">
						<div className="col-xl-12">
							<div className="card bubles">
								<div className="card-body">
									<div className="buy-coin  bubles-down">
										<div>
											<h2>Ai Powered Tools</h2>
											<p>Our suite includes nine tools that help individuals and businesses save time, optimise processes, and accelerate growth in various industries such as finance, NFT trading, and web development.</p>
											<a className="btn btn-primary">Join Presale<small> (Coming Soon)</small></a>
										</div>
										<div className="coin-img">
											<img src={coin} className="img-fluid" alt="" />
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-xl-12">
							<div class="page-titles acti-space bg-transparent mb-2 p-0">
								<h2 class="heading sm-mb-0 mb-0 fs-22">Web3Aitools</h2>
							</div></div>
						<div className="col-xl-12 tools">
							<div className='row'>
								<div className='col-xl-4'>
									<Link to="/synthix">
										<div className="widget-stat card bg-success">
											<div className="card-body p-4">
												<div class="text-white blink-hard">
													<span className='blink-circle'></span>
													Live</div>
												<div className="media ai-icon">
													<span className="me-3 bg-warning-light text-warning">
														<i className='fa fa-user text-white'></i>
													</span>
													<div className="media-body">
														<p className="mb-1 text-white">Smart Contracts</p>
														<h4 className="mb-0 text-white">Synthix</h4>
													</div>
												</div>
											</div>
										</div>
									</Link>
								</div>
								<div className='col-xl-4'>
									<Link to="/ditx">
										<div className="widget-stat card bg-success">
											<div className="card-body p-4">
											<div class="text-white blink-hard">
													<span className='blink-circle'></span>
													Live</div>
												<div className="media ai-icon">
													<span className="me-3 bg-warning-light text-warning">
														<i className='fas fa-list text-white'></i>
													</span>
													<div className="media-body">
														<p className="mb-1 text-white">Bug finder</p>
														<h4 className="mb-0 text-white">Ditx</h4>
													</div>
												</div>
											</div>
										</div>
									</Link>
								</div>
								<div className='col-xl-4'>
									<Link to="/coming-soon">
										<div className="widget-stat card">
											<div className="card-body p-4">
												<div className="media ai-icon">
													<span className="me-3 bg-warning-light text-warning">
														<i className='fa fa-paint-brush text-info'></i>
													</span>
													<div className="media-body">
														<p className="mb-1">image creation</p>
														<h4 className="mb-0">Arti</h4>
													</div>
												</div>
											</div>
										</div>
									</Link>
								</div>
								<div className='col-xl-4'>
									<Link to="/coming-soon">
										<div className="widget-stat card">
											<div className="card-body p-4">
												<div className="media ai-icon">
													<span className="me-3 bg-warning-light text-warning">
														<i className='fa fa-check text-primary'></i>
													</span>
													<div className="media-body">
														<p className="mb-1">algorithm</p>
														<h4 className="mb-0">Algora</h4>
													</div>
												</div>
											</div>
										</div>
									</Link>
								</div>
								<div className='col-xl-4'>
									<Link to="/coming-soon">
										<div className="widget-stat card">
											<div className="card-body p-4">
												<div className="media ai-icon">
													<span className="me-3 bg-warning-light text-warning">
														<i className='fa fa-bullseye text-info'></i>
													</span>
													<div className="media-body">
														<p className="mb-1">Real-time updates</p>
														<h4 className="mb-0">Snipe Ai</h4>
													</div>
												</div>
											</div>
										</div>
									</Link>
								</div>
								<div className='col-xl-4'>
									<Link to="/coming-soon">
										<div className="widget-stat card">
											<div className="card-body p-4">
												<div className="media ai-icon">
													<span className="me-3 bg-warning-light text-warning">
														<i className='fa fa-id-badge text-warning'></i>
													</span>
													<div className="media-body">
														<p className="mb-1">safeguard wallets</p>
														<h4 className="mb-0">Fraud Guard</h4>
													</div>
												</div>
											</div>
										</div>
									</Link>
								</div>
								<div className='col-xl-4'>
									<Link to="/coming-soon">
										<div className="widget-stat card">
											<div className="card-body p-4">
												<div className="media ai-icon">
													<span className="me-3 bg-warning-light text-warning">
														<i className='fa fa-search text-warning'></i>
													</span>
													<div className="media-body">
														<p className="mb-1">profile tracking</p>
														<h4 className="mb-0">Aitrac</h4>
													</div>
												</div>
											</div>
										</div>
									</Link>
								</div>
								<div className='col-xl-4'>
									<Link to="/coming-soon">
										<div className="widget-stat card">
											<div className="card-body p-4">
												<div className="media ai-icon">
													<span className="me-3 bg-warning-light text-warning">
														<i className='fa fa-laptop text-primary'></i>
													</span>
													<div className="media-body">
														<p className="mb-1">website generation</p>
														<h4 className="mb-0">SiteGenie</h4>
													</div>
												</div>
											</div>
										</div>
									</Link>
								</div>
								<div className='col-xl-4'>
									<Link to="/coming-soon">
										<div className="widget-stat card">
											<div className="card-body p-4">
												<div className="media ai-icon">
													<span className="me-3 bg-warning-light text-warning">
														<i className='fa fa-pencil text-pink'></i>
													</span>
													<div className="media-body">
														<p className="mb-1">article creation</p>
														<h4 className="mb-0">Ai Scrib</h4>
													</div>
												</div>
											</div>
										</div>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-xl-3">
					<div className="row">
						<div className="col-xl-12 col-sm-12">
							<div className="card bg-secondary email-susb">
								<div className="card-body text-center">
									<div className="">
										<img src={preSaleImage} alt="" />
									</div>
									<div className="toatal-email">
										<h3>Join Presale</h3>
										<h5>Get in early tokens on the Web3Aitools</h5>
									</div>
									<a className="btn btn-primary btn-sm">Join Presale <small>(Coming Soon)</small></a>
								</div>

							</div>
						</div>
						<div className="col-xl-12 col-sm-6">
							<div className="card">
								<div className="card-header border-0 pb-0">
									<div>
										<h2 className="heading">Tools For</h2>
									</div>
								</div>
								<div className="card-body pt-0 px-0">
									<div className="previews-info-list">
										<div className="pre-icon">
											<span className="icon-box icon-box-sm bg-success d-flex align-items-center justify-content-center"><i class="material-icons text-white">trending_up</i>
											</span>
											<div className="ms-2"><h6>Trading</h6>
												<span>Web3 Ai Solution</span>
											</div>
										</div>
									</div>
									<div className="previews-info-list">
										<div className="pre-icon">
											<span className="icon-box icon-box-sm bg-warning">
												<i class="fa fa-bullseye text-white"></i>
											</span>
											<div className="ms-2"><h6>Sniping</h6>
												<span>Web3 Ai Solution</span>
											</div>
										</div>
									</div>
									<div className="previews-info-list">
										<div className="pre-icon">
											<span className="icon-box icon-box-sm bg-pink">
												<i className='fas fa-key text-white'></i>
											</span>
											<div className="ms-2"><h6>Security</h6>
												<span>Web3 Ai Solution</span>
											</div>
										</div>
									</div>
									<div className="previews-info-list">
										<div className="pre-icon">
											<span className="icon-box icon-box-sm bg-primary">
												<i className='far fa-images text-white'></i>
											</span>
											<div className="ms-2"><h6>Arti</h6>
												<span>Web3 Ai Solution</span>
											</div>
										</div>
									</div>
									<div className="previews-info-list">
										<div className="pre-icon">
											<span className="icon-box icon-box-sm bg-info">
												<i className='fas fa-file-alt text-white'></i>
											</span>
											<div className="ms-2"><h6>Audit</h6>
												<span>Web3 Ai Solution</span>
											</div>
										</div>
									</div>
									<div className="previews-info-list">
										<div className="pre-icon">
											<span className="icon-box icon-box-sm bg-warning">
												<i className='fas fa-code text-white'></i>
											</span>
											<div className="ms-2"><h6>Smart Contract</h6>
												<span>Web3 Ai Solution</span>
											</div>
										</div>
									</div>
									<div className="previews-info-list">
										<div className="pre-icon">
											<span className="icon-box icon-box-sm bg-primary">
												<i className='fa fa-external-link text-white'></i>
											</span>
											<div className="ms-2"><h6>Publishing</h6>
												<span>Web3 Ai Solution</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export default Home;