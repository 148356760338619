import React, { useState } from "react";
import { Configuration, OpenAIApi } from "openai";
import { toolItems } from '../../../AIOptions';
import AuditBoard from './AuditBoard';

const Ditx = () => {
   const configuration = new Configuration({
      apiKey: process.env.REACT_APP_API_KEY
   });
   const openai = new OpenAIApi(configuration);
   const [result, setResult] = useState("");
   const [loading, setLoading] = useState(false);
   const [error, setError] = useState("");
   const smartPromt = toolItems[1];

   const onAudit = async (generateData) => {
      const promtText = `Please audit the below code and give back the correct code: ${generateData.script}`;
      let object = { ...smartPromt.option, prompt: promtText };
      setError("");
      setLoading(true);
      try {
         const response = await openai.createCompletion(object);
         setLoading(false);
         setResult(response.data.choices[0].text);
      } catch (e) {
         setLoading(false);
         setError("Oops! Something went wrong on our servers. Our team has been notified of the issue and is working to fix it as soon as possible.")
      }
   };

   return <AuditBoard onAudit={onAudit} result={result} setResult={setResult} error={error} setError={setError} loading={loading} />
};

export default Ditx;
