
export const MenuList = [
    //Dashboard
    {
        title:'Dashboard',
        iconStyle: <i className="material-icons">grid_view</i>,
        to: 'dashboard'
    },
    {
        title: 'Synthix',
        iconStyle: <i className="material-icons">person</i>,
        to: 'synthix',				
    },
    {
        title: 'Ditx',
        iconStyle: <i className="material-icons">list</i>,
        to: 'ditx',
    },
    {
        title: 'Arti',
        iconStyle: <i className="material-icons">brush</i>,
        to: 'coming-soon',
    },
    {
        title: 'Algora',
        iconStyle: <i className="material-icons"> check </i>,
        to: 'coming-soon',				
    },
    {
        title: 'Snipe Ai',
        iconStyle: <i className="material-icons"> radar </i>,
        to: 'coming-soon',				
    },
    {
        title: 'Fraud Guard',
        iconStyle: <i className="material-icons"> badge </i>,
        to: 'coming-soon',				
    },
    {
        title: 'Aitrac',
        iconStyle: <i className="material-icons">search</i>,
        to: 'coming-soon',				
    },
    {
        title: 'SiteGenie',
        iconStyle: <i className="material-icons"> tv </i>,
        to: 'coming-soon',				
    },
    {
        title: 'Ai Scrib',
        iconStyle: <i className="material-icons">edit</i>,
        to: 'coming-soon',				
    },    
]