import React from "react";
import { Link } from "react-router-dom";
import logo from '../../../images/logo/logo.png';

const ComingSoon = () => {
    return (
        <div className="authincation radius-small">
        <div className="container ">
           <div className="row justify-content-center h-100 align-items-center ">
              <div className="col-md-8">
                 <div className="form-input-content text-center error-page">
                 <img src={logo} alt="" className="width-150 mCS_img_loaded" />
                    <h4 className="mt-5 text-white">Upcoming Tool Release</h4>
                    <h5 className="text-white mb-5 sub-title display-6 fs-22"> Stay Tuned and Check <Link className="text-white" to="/synthix">Synthix</Link>!</h5>
                    <p className="text-white mb-1">We are currently in the pre-sale ready phase and looking forward to launching this tool.</p>
                    <p className="text-white">Please stay tuned for updates and be sure to check out our previously released AI tool, Synthix, while you wait for the remaining tools to become available.</p>
                    <div className="mt-5">
                       <Link className="btn btn-primary" to="/synthix">
                          Check Synthix
                       </Link>
                    </div>
                 </div>
              </div>
           </div>
        </div>
     </div>
    );
};

export default ComingSoon;
