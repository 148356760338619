import React from "react";

const Footer = () => {
	var d = new Date();
	return (
		<div className="footer out-footer">
			<div className="copyright">
				<p>All Rights Reserved. © 2023 Web3AiTools</p>
			</div>
		</div>
	);
};

export default Footer;
