export const toolItems = [
    {
      name: "Smart Contract Code",
      id: "SmartCode",
      description: "Writing Solidity code using openzepplin",
      option: {
        model: "text-davinci-003",
        temperature: 0.7,
        max_tokens: 1500,
        top_p: 1.0,
        frequency_penalty: 0.0,
        presence_penalty: 0.0,
      },
    },
    {
      name: "Smart Contract Audit",
      id: "ContractAudit",
      description: "Audting Smart Contract",
      option: {
        model: "text-davinci-003",
        temperature: 0.7,
        max_tokens: 1500,
        top_p: 1.0,
        frequency_penalty: 0.0,
        presence_penalty: 0.0,
      }
    }
  ];



  export const NetworkStandards = [
    {
      name: "Ethereum",
      value:"ethereum",
      standards:[
        {name:"ERC-20", value:"ERC-20"},
        {name:"ERC-1155", value:"ERC-1155"},
        {name:"ERC-721", value:"ERC-721"}
      ]
    },
    {
        name: "Binance",
        value:"binance",
        standards:[
          {name:"BEP-20", value:"BEP-20"}
        ]
      },
  ];