import React, { Fragment, useState } from "react";
import { useAccount } from 'wagmi';
import { CopyBlock, dracula } from "react-code-blocks";
import { NetworkStandards } from "../../../AIOptions";
import loaderImage from '../../../images/loader-1.gif';
import swal from "sweetalert";

export default function CodingBoard({ onGenerate, result, loading, setResult, error, setError }) {
    const [formData, setFormData] = useState({
        network: '',
        standard: '',
        name: '',
        symbol: '',
        supply: '',
        price: '',
        stax: '',
        btax: ''
    });

    const { isConnected } = useAccount();

    const [validate, setValidate] = useState(false);

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };

    const onReset = () => {
        setValidate(false);
        setResult("");
        setError("");
        setFormData({
            network: '',
            standard: '',
            name: '',
            symbol: '',
            supply: '',
            price: '',
            stax: '',
            btax: ''
        });
    }

    return (
        <div className="row">
            <div className="col-xl-6">
                <div className="card">
                    <div className="card-body">
                        <h4 className="mb-0">Create Smart Contract</h4>
                        <hr />
                        <section>
                            <div className="row">
                                <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3"><label className="text-label">Select Chain*</label>
                                        <select className={!formData.network && validate ? "form-control border-danger" : "form-control"} name="network" value={formData.network} onChange={handleChange}>
                                            <option value="">Select</option>
                                            {
                                                NetworkStandards.map((item, index) => {
                                                    return <option key={index} value={item.value}>{item.name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3"><label className="text-label">Standard*</label>
                                        <select className={!formData.standard && validate ? "form-control border-danger" : "form-control"} name="standard" value={formData.standard} onChange={handleChange}>
                                            <option>Select</option>
                                            {
                                                formData.network && (<Fragment>{
                                                    NetworkStandards.filter(item => {
                                                        return item.value === formData.network
                                                    })[0].standards.map((item, index) => {
                                                        return <option key={index} value={item.value}>{item.name}</option>
                                                    })
                                                } </Fragment>)
                                            }

                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3"><label className="text-label">Token Name*</label><input type="text"
                                        name="name" value={formData.name} onChange={handleChange} className={!formData.name && validate ? "form-control border-danger" : "form-control"} placeholder="" required="" /></div>
                                </div>
                                <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3"><label className="text-label">Symbol*</label><input type="text"
                                        name="symbol" value={formData.symbol} onChange={handleChange} className={!formData.symbol && validate ? "form-control border-danger" : "form-control"} placeholder="" required="" /></div>
                                </div>
                                <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3"><label className="text-label">Token Supply*</label><input type="text"
                                        name="supply" value={formData.supply} onChange={handleChange} className={!formData.supply && validate ? "form-control border-danger" : "form-control"} placeholder="" required="" /></div>
                                </div>
                                <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3"><label className="text-label">Initial Price (USD)</label><input type="text"
                                        name="price" value={formData.price} onChange={handleChange} className="form-control" placeholder="" required="" /></div>
                                </div>
                                <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3"><label className="text-label">Buy Tax (%)</label><input type="text"
                                        name="btax" value={formData.btax} onChange={handleChange} className="form-control" placeholder="" required="" /></div>
                                </div>
                                <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3"><label className="text-label">Sell Tax (%)</label><input type="text"
                                        name="stax" value={formData.stax} onChange={handleChange} className="form-control" placeholder="" required="" /></div>
                                </div>
                            </div>
                        </section>
                        <hr />
                        <div class="text-end p-2"><button onClick={onReset} className="btn btn-warning light">Clear</button> <button class="btn btn-primary" onClick={() => {
                            if (isConnected) {
                                if (formData.network && formData.standard && formData.name && formData.symbol && formData.supply) {
                                    onGenerate(formData);
                                } else {
                                    setValidate(true);
                                }
                            } else {
                                swal("Please Connect Wallet");
                            }

                        }} disabled={loading}>Generate</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-6">
                <div className="card">
                    <div className="card-body align-items-center justify-content-center d-flex min-h-300">
                        <section className="w-100">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div>
                                        {!loading && (result.length <= 0 && error.length <= 0) ? <h6 className="display-6 fs-22 text-muted text-center">Please fill the form to get your customized smart contract</h6> : ""}
                                        {loading ? <Fragment><div role="alert" className="fade alert alert-info alert-dismissible show fs-16 mb-3"><i className="fa fa-info-circle"></i><strong> Stay Tune! </strong> Our Ai will create best contract for your need.</div><img className="img-fluid" src={loaderImage} alt="loading" /></Fragment> : (<div className="code-format">
                                            {result.length > 0 ? <CopyBlock
                                                text={result}
                                                language="typescript"
                                                showLineNumbers={true}
                                                theme={dracula}
                                                codeBlock
                                            /> : ""}
                                        </div>)}
                                        {error.length > 0 ? <div className="display-6 fs-18 text-danger p-5">{error}</div> : ""}
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div >
    );
}