import React, { useState } from "react";
import { Configuration, OpenAIApi } from "openai";
import { toolItems } from '../../../AIOptions';
import CodingBoard from './CodingBoard';

const Synthix = () => {
   const configuration = new Configuration({
      apiKey: process.env.REACT_APP_API_KEY
   });
   const openai = new OpenAIApi(configuration);
   const [result, setResult] = useState("");
   const [loading, setLoading] = useState(false);
   const [error, setError] = useState("");
   const smartPromt = toolItems[0];

   const onGenerate = async (generateData) => {
      const promtText = `please write a smart contract code using below details: Network: ${generateData.network}, Standard: ${generateData.standard}, Token Name: ${generateData.name}, Token Symbol: ${generateData.symbol}, Total Supply: ${generateData.supply}, Intial Price: ${generateData.price} USD, Buy Tax: ${generateData.btax}, Sell Tax: ${generateData.stax}`;
      let object = { ...smartPromt.option, prompt: promtText };
      setError("");
      setLoading(true);
      try {
         const response = await openai.createCompletion(object);
         setLoading(false);
         setResult(response.data.choices[0].text);
      } catch (e) {
         setLoading(false);
         setError("Oops! Something went wrong on our servers. Our team has been notified of the issue and is working to fix it as soon as possible.")
      }
   };

   return <CodingBoard onGenerate={onGenerate} result={result} setResult={setResult} error={error} setError={setError} loading={loading} />
};

export default Synthix;
